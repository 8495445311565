$(() => {


	$('.production-section__bg__img').each((k, i) => {

		fromTo($(i), {
			y: "-50%"
		}, {
			y: "30%",
			ease: 'linear',
			scrollTrigger: {
				start: "top bottom",
				end: "bottom top",
				//pin: true,
				trigger: $(i).closest('.production-section'),
				scrub: true,
			}
		});

	});


});