$(() => {

	init_form_fields();

	$('.js-form-validate').each((k, elem) => {
		let form = $(elem);

		form_btn_validate(form, false);
	});


	$(document).on('input', '.required input,.required textarea', (elem) => {
		let itm = $(elem.currentTarget);

		if (itm.val().length > 0) {
			itm.closest('.input').removeClass("error");
		} else {
			itm.parent().addClass("error");
		}
	});

	$(document).on('input', '.js-form-validate .required input, .js-form-validate .required textarea', (elem) => {
		let itm = $(elem.currentTarget);

		validate_on_input(itm);
	});
	$(document).on('change', '.js-form-validate .required input', (elem) => {
		let itm = $(elem.currentTarget);

		validate_on_input(itm);
		itm.parent().removeClass("error");
	});

	function validate_on_input(itm) {
		//Чтобы все обработчики успели отработать
		setTimeout(() => {
			let error = false;

			if (!check_input(itm, true)) {
				error = true;
			}

			let form = itm.closest('form');

			if (form_validate(form, false)) {
				form.find('button').removeClass("disabled");
			} else {
				form.find('button').addClass("disabled");
			}
		}, 0)
	}

	$(document).on('submit', '.js-form-validate', (e) => {
		let form = $(e.currentTarget);

		if (!form_validate(form, true)) {
			$('.main').removeClass('wait');
			return false;
		}

		form.find('button').addClass("disabled");

		$('.modal-before-send').addClass("send-hide");
		$('.modal-after-send').addClass("show");
		$('.modal-content').animate({
			scrollTop: 0
		}, 600);
	});

	$(document).on('input', 'textarea', (e) => autosize(e.currentTarget));

	setTimeout(function () {
		$('textarea').each((k, e) => autosize(e));
	}, 300);


	$(document).on('dragenter', '.input-file', (e) => {
		let self = $(e.currentTarget)

		self.addClass('drag');
	});

	/*$(document).on('mouseleave', '.input-file', (e) => {
		console.log('mouseleave');

		let self = $(e.currentTarget);
		self.removeClass('drag');
	});*/

	$(document).on('dragleave', '.input-file', (e) => {
		let self = $(e.currentTarget);

		self.removeClass('drag');
	});

	$(document).on('input', '.input-file input', (e) => {
		let self = $(e.currentTarget),
			block = self.closest('.input-file'),
			file = e.currentTarget.files,
			max_size = +self.attr('data-size'),
			accept_string = self.attr('accept'),
			accept = [],
			empty_file_list = self.closest(".input-file-empty").find('.input-file-label:not(.file-fill)'),
			error_block = block.find('.input-hint-error');

		block.removeClass('loading load');
		block.removeClass('drag');

		if (file.length > 5) {
			error_block.text('Максимальное количество файлов: 5').slideDown(300);
			self.val('');
			return false;
		}


		//block.find('.input-file__list').html("");

		if (file.length > 0) {
			let re = /(?:\.([^.]+))?$/;

			for (var i = 0, itm; itm = file[i]; i++) {
				let type = re.exec(itm.name)[1],
					size = itm.size;

				if (accept_string) {
					accept_string = accept_string.replaceAll(' ', '');
					accept_string = accept_string.replaceAll('.', '');
					accept = accept_string.split(',');

					if (accept.indexOf(type) === -1) {
						error_block.text('Загружен недопустимый тип файла').slideDown(300);
						self.val('');
						return false;
					}
				}

				if (size > max_size * 1024 * 1024) {
					error_block.text('Максимальный размер файла: ' + max_size + ' мб').slideDown(300);
					self.val('');
					return false;
				}
				let file_index = self.parent().index();
				block.find('.input-file__list').append('<div class="input-file-list-itm"><div class="input-file-list-itm__name">' + itm.name + '</div><div class="input-file-list-itm__remove js-remove-file" data-index="' + file_index + '"><svg><use xlink:href="' + window.template_path + '/img/sprite.svg#basket"></use></svg></div></div>');

			}


			block.addClass('has-file');

			self.closest('.input-file-label').addClass("file-fill").removeClass("active");
			empty_file_list = self.closest(".input-file-empty").find('.input-file-label:not(.file-fill)')
			empty_file_list.first().addClass("active");

			if (empty_file_list.length <= 0) {
				block.addClass('file-full');
			}
		}
	});

	$(document).on('click', '.js-remove-file', (e) => {
		let self = $(e.currentTarget),
			block = self.closest('.input-file'),
			index = parseInt(self.attr('data-index')),
			input = block.find('input').eq(index)

		input.val('');
		input.parent().removeClass("file-fill");

		let empty_file_list = block.find('.input-file-label:not(.file-fill)')
		empty_file_list.first().addClass("active");

		self.closest(".input-file-list-itm").remove()
		block.removeClass('file-full');

		let file_count = block.find('.input-file-label.file-fill').length;
		if (file_count === 0) {
			block.removeClass("has-file");
		}
	});

	$(document).on('click', '.js-file-remove', (e) => {
		let self = $(e.currentTarget),
			block = self.closest('.input-file');

		block.removeClass('drag');
		block.removeClass('has-file');
		block.find('input').val('');
	})

});

function form_validate(form, show_error = true) {
	let error = false,
		requireds = form.find('.required input, .required select, .required textarea');

	requireds.each((k, elem) => {
		let itm = $(elem);

		let hint = itm.closest(".required").find('.input-hint');
		let form = itm.closest('form');

		if (!check_input(itm, show_error)) {
			error = true;
			if (show_error) {
				itm.closest(".required").addClass("error");
				//hint.stop().text('Обязательно поле');
				hint_show(hint);
			}
		} else {
			/*hint.stop().slideUp(300,function (){
				hint.text('');
			});*/
		}

		if (!error) {
			form.find('button').removeClass("disabled");
		} else {
			form.find('button').addClass("disabled");
		}
	});


	return !error;
}

function check_input(input, show_error = false) {
	let parent = input.closest('.required');

	if (input.closest('.hide').length > 0) {
		return true;
	}

	let hint = parent.find('.input-hint');


	if (show_error) {
		parent.removeClass("error");
	}

	if ((input.attr('type') === 'checkbox') && input.prop('checked') === false) {
		if (show_error) {
			hint.text('Поле обязательно для заполнения');
			hint_show(hint);
			input.closest('.check_box').addClass("error");
		}
		return false;
	}

	if (input.attr('type') === 'radio') {
		let name = input.attr('name');

		if ($('input[name="' + name + '"]:checked').length === 0) {
			return false;
		}
	}

	if (input.attr('type') === 'file' && input.hasClass('js-input-avatar')) {
		let block = input.closest('.change-avatar');

		if (!block.hasClass('empty')) {
			return true;
		}
	}

	if (input.attr('type') === 'tel') {
		let tel_error = input.val().indexOf('_') !== -1 || input.val().length === 0;

		if (show_error && tel_error) {
			hint.text('Поле обязательно для заполнения');
			hint_show(hint);
			parent.addClass("error");
		}
		return !tel_error;
	}

	if (input.val().length <= 0) {
		if (show_error) {
			hint.text('Поле обязательно для заполнения');
			hint_show(hint);
			parent.addClass("error");
		}

		return false;
	}

	if (input.attr('type') === 'email') {
		let is_email = input.val().match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);

		if (is_email == null && input.val().length > 0) {
			hint.text('Неверно заполнена эл. почта ');
			hint_show(hint);
			parent.addClass("error");
			return false;
		}
	}

	if (show_error && input.val().length > 0) {
		hint_hide(hint);
	}

	return input.val().length !== 0;

}


const form_btn_validate = (form, show_error) => {
	if (form_validate(form, show_error)) {
		form.find('button').removeClass("disabled");
	} else {
		form.find('button').addClass("disabled");
	}
}

const hint_show = (hint) => {
	let tl = gsap.timeline();

	to(hint, {
		height: 'auto',
		overwrite: true,
		opacity: 1,
	}, tl);
}
const hint_hide = (hint) => {
	let tl = gsap.timeline();

	to(hint, {
			height: 0,
			opacity: 0,
			overwrite: true,
			onComplete: () => {
				hint.text('');
			}
		}, tl
	);
}

const init_form_fields = () => {
	init_radio_style();
	init_checkbox_style();
	init_tel_mask();
}

const init_radio_style = () => {
	$('input.js-radio').each((k, elem) => {
		let itm = $(elem);

		if (!itm.parent().is('.radio_box')) {
			var placeholder = itm.attr('data-placeholder');

			itm.wrap('<label class="radio_box"></label>');
			itm.after('<span class="radio_box__item"></span><span class="radio_box__text">' + placeholder + '</span>');
		}

		if (itm.prop('checked')) {
			itm.parent('.radio_box').addClass('checked');
		} else {
			itm.parent('.radio_box').removeClass('checked');
		}
	});
};

const init_checkbox_style = () => {
	$('input.js-checkbox').each((k, elem) => {
		let itm = $(elem);

		if (!itm.parent().is('.check_box')) {
			var placeholder = itm.attr('data-placeholder');

			itm.wrap('<label class="check_box"></label>');
			itm.after('<span class="check_box__item"></span><span class="check_box__text">' + placeholder + '</span>');
		}

		if (itm.prop('checked')) {
			itm.parent('.check_box').addClass('checked');
		} else {
			itm.parent('.check_box').removeClass('checked');
		}
	});
};

const init_tel_mask = () => {
	$('[type="tel"]').each((k, elem) => {
		let mask = '+7 (999) 999-99-99'
		$(elem).inputmask({
			mask: mask, showMaskOnHover: false,
			"oncomplete": function () {
				let parent = $(elem).closest('.input'),
					hint = parent.find('.input-hint')

				hint_hide(hint);
				parent.addClass("phone-complete");
			},
			"onincomplete": function () {
				let parent = $(elem).closest('.input'),
					hint = parent.find('.input-hint')

				parent.removeClass("phone-complete");
				hint_hide(hint);
			}
		});
	});
}

const autosize = (itm) => {
	itm.style.height = 'auto';
	let applyNow = itm.style.offsetHeight;
	itm.style.height = itm.scrollHeight + 5 + 'px';
}