var myFullpage;

$(() => {
	var is_portrait = window.matchMedia('all and (max-width: 850px)').matches;
	let can_move = true;

	$(window).on('resize', () => {
		if ($('#window-scroll').length === 0) return;
		if (is_portrait !== window.matchMedia('all and (max-width: 850px)').matches) {
			is_portrait = window.matchMedia('all and (max-width: 850px)').matches;
			if (is_portrait) {
				$.fn.fullpage.destroy('all');
			} else {
				let prev_index = $('.window.fp-completely').index();

				fullpage_init();
				change_slide(prev_index + 1, 1, 'up');
			}
		}
	});

	$(window).on('scroll', (e) => {
		let self = $(e.currentTarget),
			scroll = $(window).scrollTop()

		if (is_mobile) {
			let footer = $('.window-footer')

			if (scroll > footer.offset().top) {
				$('.header').removeClass("header--white");
			} else {
				$('.header').addClass("header--white");
			}
		}
	});

	const text_line_split = () => {
		$('.window-title,.animate-title').each((k, i) => {
			let block = $(i),
				text = block.html().split('<br>')

			block.html("");

			text.forEach((i, k) => {
				block.append("<div class='animate-line'><p class='js-animate-line'> " + i + "</p></div>");
			})
		})

	}

	text_line_split();

	const animate_slide = (prevSection, nextSection, direction = "down") => {
		let tl = gsap.timeline();

		to(prevSection.find('.js-animate-line'), {
			opacity: 0,
			y: -80,
			duration: .8,
			ease: "easeNewton"
		}, tl);

		to(prevSection.find('.window-subtitle'), {
			opacity: 0,
			y: -80,
			duration: .4,
			overwrite: true
		}, tl, '<+.1');

		to(prevSection.find('.arrow-link, .window-bot__subtitle, .front-footer-tel, .footer'), {
			opacity: 0,
			y: -25,
			duration: .4,
			stagger: .2,
			overwrite: true
		}, tl, "<+=.05");

		to(prevSection.find('.owl-item.active .front-slider-itm__subtitle'), {
			opacity: 0,
			y: -25,
			duration: .4,
			overwrite: true
		}, tl, '<');

		to(prevSection.find('.owl-dot__name'), {
			opacity: 0,
			duration: .4,
			stagger: .2,
			overwrite: true
		}, tl, '<+=.05');

		to(prevSection.find('.progress-bar'), {
			opacity: 0,
			duration: .4,
			stagger: .2,
		}, tl, '<');

		let change_time = 2;

		if (nextSection.index() === 1) {
			change_time = 2.5;
		}
		if (nextSection.index() === 2) {
			change_time = 0.5;
		}
		if (nextSection.index() === 3) {
			change_time = 4.5;
		}

		if (direction === 'up') {
			change_time = 0.6;
		}

		if (prevSection.find('.front-slider').length > 0 && direction === 'up') {
			change_time = 0;
		}

		to(prevSection.find('.footer-main'), {
			duration: 0,
			onComplete: () => {
				$('.header').addClass("header--white");
				gsap.to($('.front-page-pagination'), {
					opacity: 1,
					overwrite: 'auto',
				})
			}
		}, tl, "<");

		to(nextSection.find('.footer-main'), {
			duration: 0,
			onComplete: () => {
				$('.header').removeClass("header--white");
				gsap.to($('.front-page-pagination'), {
					opacity: 0,
					overwrite: 'auto',
				})
			}
		}, tl, "<");

		let duration = 0;

		if (nextSection.hasClass('window-footer') || prevSection.hasClass('window-footer')) {
			change_time = 0.2;
			duration = .5;
		}

		if (prevSection.find('.front-slider').length > 0) {
			change_time = 0.2;
			duration = .5;
		}


		to(nextSection, {
			opacity: 1,
			//duration: change_time,
			delay: change_time,
			duration: duration,
			overwrite: true,
		}, tl);

		to(prevSection, {
			opacity: 0,
			//duration: change_time,
			duration: duration,
			overwrite: true,
			onComplete: () => {
				let nextIndex = nextSection.index() + 1,
					cur_page_text = nextIndex < 10 ? "0" + nextIndex : nextIndex;

				if (nextSection.find('.footer-main').length === 0) {
					$('.js-front-current-page').text(cur_page_text);
				}

				if (prevSection.find('.front-slider').length > 0) {
					front_slider_stop();
				}

			}
		}, tl, "<");


		let change_title_delay = 0;
		if (direction === 'up') {
			if (prevSection.index() === 1) {
				change_title_delay = 2;
			}
			if (prevSection.index() === 2) {
				change_title_delay = 0;
			}
			if (prevSection.index() === 3) {
				change_title_delay = 2;
			}
		}

		fromTo(nextSection.find('.js-animate-line'), {
			opacity: 0,
			y: 80
		}, {
			opacity: 1,
			y: 0,
			duration: .8,
			delay: change_title_delay,
			stagger: .2,
			ease: "easeNewton"
		}, tl);

		fromTo(nextSection.find('.window-subtitle,.front-footer-tel'), {
			opacity: 0,
			y: 80
		}, {
			opacity: 1,
			y: 0,
			duration: .8,
			overwrite: true
		}, tl, '<+=.1');

		fromTo(nextSection.find('.owl-item.active .front-slider-itm__subtitle'), {
				opacity: 0,
				y: 40
			}, {
				y: 0,
				opacity: 1,
			}, tl, '<+=.4'
		);

		fromTo(nextSection.find('.owl-dot__name'), {
			opacity: 0,
			y: 50
		}, {
			opacity: 1,
			y: 0,
			duration: .8,
			stagger: .2,
			overwrite: true,
			onComplete: () => {
				if (nextSection.find('.front-slider').length > 0) {
					front_slider_start();
				}
			}
		}, tl, '<+=.1');

		fromTo(nextSection.find('.progress-bar'), {
			opacity: 0,
			y: 40
		}, {
			opacity: 1,
			y: 0,
			duration: .8,
			stagger: .2,
			overwrite: true
		}, tl, '<');

		fromTo(nextSection.find('.arrow-link, .window-bot__subtitle'), {
			opacity: 0,
			y: 50
		}, {
			opacity: 1,
			y: 0,
			duration: .8,
			stagger: .2,
		}, tl, "<+=.4");


		fromTo(nextSection.find('.footer'), {
			y: 20,
			opacity: 0,
		}, {
			y: 0,
			opacity: 1,
			duration: .4,
			overwrite: true,
			ease: 'linear'
		}, tl, '>-=.4');

		to($('.wrapper'), {
			y: 0,
			onComplete: () => {
				can_move = true;
			}
		}, tl, '<');

		if (prevSection.find('.front-slider').length > 0) {
			$('.front-slider').trigger('stop.owl.autoplay');
			to($('.front-slider .progress-bar__val'), {
				x: 0,
				duration: 0,
				overwrite: true,
			});
		}

	}

	const change_slide = (index, nextIndex, direction) => {
		let nextSection = $('.fp-section').eq(nextIndex - 1),
			max_index = $('.fp-section').length,
			prevSection = $('.fp-section').eq(index - 1),
			video_active = $('.front-video.active,.front-video-reverse.active'),
			tl = gsap.timeline(),
			video_tl = gsap.timeline(),
			video = $('.front-video[data-index=' + (nextIndex - 1) + ']');

		$('.wrapper').removeClass("direction-up direction-down");
		$('.wrapper').addClass(`direction-${direction}`);

		if (is_tablet) return;

		if ($('.wrapper').hasClass('modal-opened')) return false;


		$('.window-scroll-nav-itm').removeClass('active');
		$('.window-scroll-nav-itm').eq(nextIndex - 1).addClass('active');


		if (nextIndex >= max_index) {
			$('.footer').addClass('show');
		} else {
			$('.footer').removeClass('show');
		}

		video_active.removeClass("active");
		let video_delay = prevSection.find('.front-slider').length > 0 ? .3 : 0
		let video_duration = prevSection.find('.front-slider').length > 0 ? .8 : 0.2

		fromTo(video_active, {opacity: 1}, {
			opacity: 0, duration: 0.2, delay: .2, onComplete: () => {
				video_active[0].load();
				video_active[0].pause();
				video_active[0].currentTime = 0;
				console.log(video_active);
			}
		}, gsap.timeline())

		/*if (video_active.length > 0) {
			video_active[0].pause();

			if (video_active.next('.front-video').length === 0 && direction === 'down') {
				fromTo(video_active, {opacity: 1}, {
					opacity: 0, duration: 2, onComplete: () => {
						video_active[0].style.removeProperty('opacity');
					}
				})
			}
		}*/

		if (direction === 'up') {
			let video_prev = $('.front-video[data-index=' + nextIndex + ']');
			if (video_prev.length > 0) {
				video = $('.front-video-reverse[data-index=' + (nextIndex - 1) + ']');

				video.addClass("active");
				fromTo(video, {opacity: 0}, {
					opacity: 1, duration: video_duration, delay: video_delay, overwrite: true, onComplete: () => {
						//video[0].currentTime = 0;
						video[0].play();
					}
				}, gsap.timeline(), '<')
			}
		} else {
			if (video.length > 0) {
				let video_reverse = $('.front-video-reverse[data-index=' + (nextIndex - 2) + ']'),
					video_reverse_load = true;

				video_reverse.find('source').each((k, i) => {
					if(!$(i).attr('src')) {
						$(i).attr('src', $(i).attr('data-src'))
						video_reverse_load = false;
					}
				})

				if(!video_reverse_load){
					video_reverse[0].load()
				}


				video.addClass('active');
				fromTo(video, {opacity: 0}, {
					opacity: 1, duration: 0.2, delay: 0, overwrite: true, onComplete: () => {
						//video[0].currentTime = 0;
						if (nextIndex > 1) {
							video[0].play();
						}
					}
				}, gsap.timeline())

			}
		}

		animate_slide(prevSection, nextSection, direction);
	}


	const animate_first_load = () => {
		const tl = gsap.timeline(),
			first = $('.front-page .window').first();

		fromTo($('.preloader'), {opacity: 1}, {
			opacity: 0, duration: 1, delay: 1, ease: 'ease', onComplete: () => {
				$('.preloader').addClass("load");
				$('body').addClass("load-page");
			}
		}, tl);

		if (first.length === 0) return;

		if (!is_tablet) {
			fromTo(first.find('.js-animate-line'), {
				opacity: 0,
				y: 80
			}, {
				opacity: 1,
				y: 0,
				duration: .8,
				stagger: .2,
				ease: "easeNewton"
			}, tl);


			fromTo(first.find('.arrow-link'), {
				opacity: 0,
				y: 25
			}, {
				opacity: 1,
				y: 0,
				duration: .8,
				ease: "easeNewton"
			}, tl, "<+=.6");
		}

		fromTo($('.header>.content'), {
			y: '-100%'
		}, {
			y: 0,
			duration: .8,
			ease: "easeNewton"
		}, tl, "<+=.2");

		fromTo($('.header-blur'), {
			height: 0
		}, {
			height: "100%",
			duration: .8,
			ease: "easeNewton"
		}, tl, "<");

		fromTo($('.front-page-pagination'), {
			y: 50,
			opacity: 0
		}, {
			y: 0,
			opacity: 1,
			duration: .8,
			ease: "easeNewton"
		}, tl, "<+=.1");

		if (getCookie('cookie-popup') !== "1") {
			fromTo($('.cookie-popup'), {
				y: 50,
				opacity: 0
			}, {
				y: 0,
				opacity: 1,
				duration: .8,
				ease: "easeNewton"
			}, tl);
		}
		let video = $('.front-video[data-index="0"]');

		video.addClass('active');
		if (video.length > 0) {
			video[0].play();
		}

	}

	const animate_mobile = () => {
		let tl = gsap.timeline();
		if (!is_tablet) return;

		to($('.preloader'), {
			opacity: 0, duration: 1, ease: 'linear', onComplete: () => {
				$('.preloader').addClass("load");
				$('body').addClass("load-page");
			}
		}, tl);

		fromTo($('#front-window1 .animate-wrap'), {
			y: 35,
			opacity: 0,
		}, {
			y: 0,
			opacity: 1,
			delay: .6,
			duration: 1
		}, tl);

		$('.window__bg, .front-slider-itm__bg').each((k, i) => {
			let parent = $(i).closest('.window');

			gsap.fromTo(i, {
				y: "-20%"
			}, {
				y: "20%",
				//duration: 1,
				ease: "none",
				scrollTrigger: {
					start: "top bottom",
					end: "bottom top",
					trigger: parent,
					scrub: 0.1,
				}
			});
		});

		$('.animate-wrap, .js-animate-mobile, .arrow-link:not(.no-animate)').each((k, i) => {
			let parent = $(i).closest('.window');

			if ($(i).closest('#front-window1').length > 0) return;

			gsap.fromTo(i, {
				y: 25,
				opacity: 0,
			}, {
				y: 0,
				opacity: 1,
				duration: 1,
				scrollTrigger: {
					start: "top 80%",
					trigger: i,
				}
			});
		});


	}

	animate_first_load();

	animate_mobile();


	const fullpage_init = () => {
		$('.js-front-all-page').text($('.front-page>.window').length - 1);

		$('.body').addClass('onpage-scroll');

		myFullpage = $('#window-scroll').fullpage({
			//options here
			autoScrolling: true,
			fitToSection: true,
			scrollingSpeed: 700,
			sectionSelector: '.window',
			slideSelector: '.slide',
			responsiveWidth: 850,
			afterResize: function (width, height) {
			},
			afterLoad: function (index, nextIndex, direction) {
			},
			onLeave: function (index, nextIndex, direction) {
				if (!can_move) {
					return false;
				}
				can_move = false;

				if (nextIndex > 1) {
					$('.fly-chat').addClass("fly-chat--small");
				} else {
					$('.fly-chat').removeClass("fly-chat--small");
				}

				change_slide(index, nextIndex, direction);
			}
		});

		let first = $('.window-scroll-nav-itm').first();
		first.addClass('active');

		//methods
		$.fn.fullpage.setAllowScrolling(true);

		gsap.to($('.fp-section').first(), {opacity: 1, duration: .2});

	}

	syncHeight();

	function syncHeight() {
		$('body').css(
			'--window-inner-height',
			`${window.innerHeight}px`
		);
	}

	//window.addEventListener('resize', syncHeight);


	if ($('#window-scroll').length > 0 && !is_portrait) {
		fullpage_init();
	}

	const autoplayTimeout = 5000;

	if ($('.front-slider').length > 0) {

		$('.front-slider').owlCarousel({
			items: 1,
			nav: 0,
			dots: true,
			autoplay: 0,
			loop: true,
			checkVisible: 0,
			mouseDrag: 0,
			touchDrag: 0,
			autoplayTimeout: autoplayTimeout,
			onInitialized: (e) => {
				const slider = $(e.currentTarget),
					tl = gsap.timeline(),
					items = e.relatedTarget._items;

				slider.find('.owl-dot').each((key, i) => {
					const name = $(items[key]).find('.front-slider-itm').attr('data-tab-name');

					$(i).html("<span class='owl-dot__name'>" + name + "</span><span class='progress-bar'><span class='progress-bar__val'></span></span>")
				});


				if (!is_tablet) {
					slider.trigger('stop.owl.autoplay');
				} else {
					slider.trigger('start.owl.autoplay');
					let dot = slider.find('.owl-dot').first();

					fromTo(dot.find('.progress-bar__val'),
						{x: 0}, {
							x: "100%",
							duration: autoplayTimeout / 1000,
							overwrite: true,
							ease: 'linear'
						}, tl, "<");

					let bg = slider.find('.owl-item.active .front-slider-itm__bg-wrap');
					fromTo(bg, {opacity: 0}, {
						opacity: 1,
						duration: .4,
						overwrite: true,
					}, tl, '<');

					$('.owl-dots').each((k, i) => {
						gsap.fromTo(i, {
							y: 25,
							opacity: 0,
						}, {
							y: 0,
							opacity: 1,
							duration: 1,
							scrollTrigger: {
								start: "top 80%",
								trigger: i,
							}
						});
					});
				}

			}, onChanged: (e) => {
				let index = e.page.index;
				const slider = $(e.currentTarget),
					dots = slider.find('.owl-dot'),
					dot = dots.eq(index),
					items = e.relatedTarget._items,
					tl = gsap.timeline(),
					tl_progress = gsap.timeline(),
					dot_prev = dot.prevAll(),
					dot_next = dot.nextAll();

				slider.trigger('stop.owl.autoplay');
				slider.trigger('play.owl.autoplay', [autoplayTimeout]);

				if (index === -1) {
					let bg = $(items[1]).find('.front-slider-itm__bg-wrap');
					fromTo(bg, {opacity: 0}, {
						opacity: 1,
						duration: .4,
						overwrite: true,
					}, tl, '<');
				}

				let bg = $(items[index]).find('.front-slider-itm__bg-wrap');


				fromTo(bg, {opacity: 0}, {
					opacity: 1,
					duration: .1,
					overwrite: true,
				}, tl);

				//slides
				to(slider.find('.front-slider-itm__bg-wrap').not(bg), {
					opacity: 0, duration: .4,
					overwrite: true
				}, tl);

				to(slider.find('.front-slider-itm__subtitle'), {
					y: -40,
					duration: .8,
					opacity: 0,
					overwrite: true
				}, tl);

				fromTo($(items[index]).find('.front-slider-itm__subtitle'), {
					y: 40,
					opacity: 0
				}, {
					y: 0,
					overwrite: true,
					duration: .8,
					opacity: 1
				}, tl, '>-=.4');


				// progress bar
				to(dot_prev.find('.progress-bar__val'), {
					x: "100%",
					duration: .2,
					overwrite: true,
				}, tl_progress, "<");

				to(dot_next.find('.progress-bar__val'), {
					x: 0,
					duration: 0,
					overwrite: true,
				}, tl_progress, "<");

				fromTo(dot.find('.progress-bar__val'),
					{x: 0}, {
						x: "100%",
						duration: autoplayTimeout / 1000,
						overwrite: true,
						ease: 'linear'
					}, tl_progress, "<");

			}
		});

		/**     Пролистывание свайпами    **/

		let touchend = 1,  //закончено движение пальцем или нет
			slider = $('.front-slider'),
			prev;
		slider.on('touchstart click', function (ev) {
			if (ev.originalEvent.changedTouches) prev = ev.originalEvent.changedTouches[0].pageX;   //записываем координаты начального прикосновения
		});
		slider.on('touchmove click', function (ev) {
			if (touchend === 1) {   //если движение пальцем закончено
				if (ev.originalEvent.changedTouches) {
					var X = ev.originalEvent.changedTouches[0].pageX;
					if (Math.abs(X - prev) > 120) {//слайд совершается только при сильном свайпе
						touchend = 0;
						if (X < prev) {     //сравниваем текущии координаты с начальным положением пальца
							slider.trigger('next.owl.carousel');
						} else {
							slider.trigger('prev.owl.carousel');
						}
					}
				}
			}
		});
		slider.on('touchend click', function (ev) {
			touchend = 1;
		});

		/**     /Пролистывание свайпами    **/
	}


	const front_slider_start = () => {
		const slider = $('.front-slider');
		slider.trigger('to.owl.carousel', [0, 0]);
		slider.trigger('play.owl.autoplay', [autoplayTimeout]);

		fromTo(slider.find('.owl-dot').eq(0).find('.progress-bar__val'), {
			x: 0
		}, {
			x: "100%",
			duration: autoplayTimeout / 1000,
			overwrite: true,
			ease: 'linear'
		});
	}

	const front_slider_stop = () => {
		const slider = $('.front-slider');
		slider.trigger('to.owl.carousel', [0, 0]);
		slider.trigger('stop.owl.autoplay');

		to(slider.find('.owl-dot').eq(0).find('.progress-bar__val'), {
			x: 0
		});
	}


})


const play_video_down = ($video, start_time = false) => {

}