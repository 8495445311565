$(() => {


	const venfasad_init = () => {

		fromTo($('.ventfasad-main-bg__img'), {
			//scale: 1
		}, {
			//scale: 1.2,
			scrollTrigger: {
				start: "top top",
				end: "bottom bottom",
				pin: true,
				trigger: $('.ventfasad-main-bg'),
				scrub: true,
			}
		});


		let tl_shadow = gsap.timeline({
			scrollTrigger: {
				start: "top+=30% bottom",
				end: "+=1300",
				//pin: true,
				trigger: $('.ventfasad-hero__window-2'),
				scrub: true,
			}
		})

		fromTo($('.ventfasad-hero__shadow-image'), {
			opacity: 0
		}, {
			opacity: .7,
			duration: 6
		}, tl_shadow);
		to($('.ventfasad-hero__shadow-image'), {
			opacity: .95,
			duration: 2
		}, tl_shadow, '>+=3');

		const tl = gsap.timeline({
			scrollTrigger: {
				start: "top-=100 top",
				end: "+=300px",
				trigger: $('.ventfasad-hero__window-2'),
				scrub: true,
			}
		});
		const tl_dot = gsap.timeline({
			scrollTrigger: {
				start: "top+=35% bottom",
				end: "+=1300",
				trigger: $('.ventfasad-hero__window-2'),
				scrub: true,
			}
		});


		//показ точек
		fromTo($('.ventfasad-hero__shadow-image .mask-circle'), {
			opacity: 0,
		}, {
			opacity: 1,
			ease: 'ease',
			stagger: -.2,
		}, tl_dot);

		fromTo($('.ventfasad-hero__shadow-image .dot-circle'), {
			opacity: 0,
		}, {
			opacity: 1,
			ease: 'ease',
			stagger: -.2,
		}, tl_dot, '<+=.2');

		fromTo($('.ventfasad-hero__shadow-image .dot-text'), {
			opacity: 0,
			y: 10
		}, {
			opacity: 1,
			y: 0,
			ease: 'ease',
			stagger: -.2,
		}, tl_dot, '<+=.2');


		to($('.ventfasad-hero__shadow-image .mask-circle'), {
			opacity: 0,
			ease: 'ease',
			stagger: -.2,
		}, tl_dot);
		to($('.ventfasad-hero__shadow-image .dot-circle'), {
			opacity: 0,
			ease: 'ease',
			stagger: -.2,
		}, tl_dot,'<+.2');
		to($('.ventfasad-hero__shadow-image .dot-text'), {
			opacity: 0,
			ease: 'ease',
			stagger: -.2,
		}, tl_dot, '<+.2');


		//показ точек
		fromTo($('.ventfasad-dot'), {
			opacity: 0,
		}, {
			opacity: 1,
			ease: 'ease',
			stagger: -.2,
			onStart: () => {
				$('.ventfasad-dot').addClass("show");
			}
		}, tl);

		//скрытие точек
		to($('.ventfasad-dot'), {
			opacity: 0,
			ease: 'ease',
			stagger: -.2,
			onComplete: () => {
				$('.ventfasad-dot').removeClass("show");
			}
		}, tl, '>');


	}

	venfasad_init();

})
