$(() => {
	let scrollWidth = window.innerWidth - document.documentElement.clientWidth;
	$('html').css(
		'--scrollWidth',
		`${scrollWidth}px`
	);


	$(document).on('click', (e) => {
		let target = $(e.target)

		if (target.closest('.header-menu-sublist-li').length > 0 || target.hasClass('header-menu-sublist-li')) {
			return;
		}

		let open_header_sub = $('.header-menu-sublist-li.show-sub')
		if (open_header_sub.length > 0) {
			close_header_line_sub(open_header_sub.find('ul'));
		}

		open_header_sub.removeClass("show-sub");
		close_header_line_sub(open_header_sub.find('ul'));

	});

	scrollUnBlock();

	$('.menu-sub-accordion__head').on('click', (elem) => {
		let self = $(elem.currentTarget),
			last_active = $('.menu-sub-accordion.active'),
			block = self.closest('.menu-sub-accordion'),
			tl = gsap.timeline();

		if (block.hasClass('active')) return;

		last_active.removeClass('active');
		last_active.find('.menu-sub-accordion__content').slideUp(300);
		to(last_active.find(".menu-sub-cols"), {opacity: 0, duration: .2}, tl)

		block.addClass("active");
		block.find('.menu-sub-accordion__content').slideDown(300);

		block.find('.menu-sub-accordion__img').removeClass("active");
		block.find('.menu-sub-accordion__img').first().addClass("active");

		fromTo(block.find(".menu-sub-cols"), {opacity: 0}, {
			opacity: 1,
			duration: .6,
			overwrite: true
		}, tl, '>+=.1')

	});

	$('.js-open-header-sub').on('click', (elem) => {
		let sub = $(elem.currentTarget).closest('li').find('.header-menu-sub'),
			tl = gsap.timeline();
		$(elem.currentTarget).closest("li").toggleClass('open');
		$(elem.currentTarget).toggleClass('active');
		$('.main-bg-blur').toggleClass('active');
		$('.header').toggleClass('header--open-menu');

		close_header_line_sub($('.header-menu-sublist-li.show-sub ul'));

		$('.header-menu-sublist-li').removeClass("show-sub");

		if (sub.find('.menu-sub-accordion__img.active').length === 0) {
			sub.find('.menu-sub-accordion__img').first().addClass("active");
		}


		if ($(elem.currentTarget).hasClass('active')) {
			fromTo($('.main-bg-blur'), {opacity: 0}, {opacity: 1, duration: .4}, tl)
			to(sub, {height: 'auto', duration: .4}, tl, '<');
			scrollBlock();
			fromTo(sub.find('.menu-sub-accordion'), {opacity: 0}, {
				opacity: 1,
				stagger: .2,
				duration: .8,
				ease: 'linear',
				onComplete: () => {
					sub.addClass('active');
				}
			}, tl, '<+=.1');

		} else {
			close_menu_sub();
		}


		return false;
	});

	$('.main-bg-blur').on('click', (elem) => {
		close_menu_sub();
	});

	$('.menu-sub-cols__link').on('mouseenter', function (e) {
		let self = $(e.currentTarget),
			img = self.parent().find(".menu-sub-accordion__img");

		if (img.length === 0) return;

		$(".menu-sub-accordion__img").removeClass("active");
		img.addClass('active');
	});

	const close_menu_sub = () => {
		$('.main-bg-blur').removeClass("active");

		let sub = $('.header-menu-sub'),
			tl = gsap.timeline();

		sub.removeClass('active');

		$('.js-open-header-sub').removeClass("active");
		$('.header-menu-sub').removeClass('active');
		$('.header-menu>li').removeClass('open');


		fromTo($('.main-bg-blur'), {opacity: 1}, {opacity: 0, duration: .1}, tl, '<+=.1')

		to(sub.find('.menu-sub-accordion'), {
			opacity: 0,
			duration: .1,
			ease: 'linear'
		}, tl, '<');
		to(sub, {
			height: 0, duration: .3
		}, tl, '<');
		$('.header').removeClass('header--open-menu');
		scrollUnBlock();
	}

	$(document).on('keyup', (e) => {
		const code = e.originalEvent.keyCode;

		// ESC
		if (code === 27) {
			close_main_menu();

			if ($('.header').hasClass('header--open-menu')) {
				close_menu_sub();
			}
		}
	});

	$('.header-mobile-menu-list-btn').on('click', (e) => {
		let self = $(e.currentTarget),
			ul = self.siblings('ul'),
			need_open = !self.parent().hasClass('active');

		let active = $('.header-mobile-menu-list li.active');

		active.find('ul').slideUp(300);
		active.removeClass('active');

		if (!need_open) return;

		self.parent().addClass('active');
		ul.slideDown(300);
	});

	$('.js-open-header-menu').on('click', (e) => {
		let self = $(e.currentTarget)

		self.toggleClass('active');

		if (is_tablet) {
			$('.header-mobile-menu').toggleClass('active');
			$('.header').toggleClass('header--open-main-menu');
			self.find('.gamburger').toggleClass('active');

			if (self.hasClass('active')) {
				scrollBlock();
			} else {
				scrollUnBlock();
			}

			return;
		}

		open_main_menu();
	});

	$('.js-open-search-modal').on('click', (e) => {
		let self = $(e.currentTarget),
			tl = gsap.timeline()

		$('.modal-search').addClass("active");

		fromTo($('.modal-search .modal-fade'), {
			opacity: 0
		}, {
			opacity: 1
		}, tl);

		fromTo($('.modal-search-content,.modal-search .modal-close'), {
			opacity: 0,
			y: 15
		}, {
			opacity: 1,
			y: 0
		}, tl)

		scrollBlock();
	});

	$('.js-close-search-modal').on('click', (e) => {
		let self = $(e.currentTarget),
			tl = gsap.timeline()

		$('.modal-search').removeClass("active");

		to($('.modal-search-content,.modal-search .modal-close'), {
			opacity: 0,
			y: 15
		}, tl)

		to($('.modal-search .modal-fade'), {
			opacity: 0,
			onComplete: () => {
				scrollUnBlock();
			}
		}, tl);


	});

	$('.js-header-menu-sublist-btn').on('click', (e) => {
		let self = $(e.currentTarget),
			li = self.parent()

		$('.header-menu-sublist-li').not(li).each(function () {
			$(this).removeClass("show-sub");
			close_header_line_sub($(this).find('ul'));

		})

		li.toggleClass("show-sub");

		if (li.hasClass('show-sub')) {
			open_header_line_sub(li.find('ul'));
		} else {
			close_header_line_sub(li.find('ul'));
		}
	});

	$('.main-menu-list__link[data-target]').on('click', (e) => {
		let self = $(e.currentTarget),
			target = $("#" + self.attr('data-target'))

		if (target.length === 0) return true;

		if (target.hasClass('active')) {
			close_main_menu_sub(target);
			return true;
		}

		open_main_menu_sub(target);
		close_main_menu_sub($('.main-menu-content-itm').not(target));
	});

	$('.js-close-main-menu').on('click', (e) => close_main_menu());

	$('.js-close-cookie-popup').on('click', (e) => {
		let self = $(e.currentTarget)

		to($('.cookie-popup'), {
			y: 50,
			opacity: 0,
			duration: .8,
			ease: "easeNewton",
			onComplete: () => {
				$('.cookie-popup').addClass("hide");
			}
		}, gsap.timeline());

		setCookie("cookie-popup", 1);
	});

	if (getCookie('cookie-popup') === "1") {
		$('.cookie-popup').hide(0);
	}

	const close_main_menu = () => {
		if (is_tablet) return true;

		let tl = gsap.timeline(),
			itm = $('.main-menu')

		itm.removeClass('active');
		itm.find('.main-menu-close .gamburger').removeClass('active');

		to(itm, {
			height: '0',
			duration: .2,
			ease: 'linear',
			onComplete: () => {
				scrollUnBlock();
			}
		}, tl)


	}
	const open_main_menu = () => {
		if (is_tablet) return true;

		let tl = gsap.timeline(),
			itm = $('.main-menu')

		itm.addClass('active');
		itm.find('.main-menu-close .gamburger').addClass('active');


		fromTo(itm, {
				height: "0%"
			},
			{
				height: "100%",
				duration: .25,
				ease: 'linear'
			}, tl, '<')

		fromTo(itm.find('.main-menu-list li').find("a,button,>span"), {
				opacity: 0,
				y: -35,
			},
			{
				opacity: 1,
				stagger: .075,
				duration: .75,
				y: 0,
			}, tl, '<+=.1')

		/*fromTo(itm.find('.main-menu-sublist li').find("a,button"), {
				opacity: 0,
				y: -35,
			},
			{
				opacity: 1,
				stagger: .1,
				duration: 1,
				y: 0,
			}, tl, '<+=.2')*/

		scrollBlock()
	}
	const close_main_menu_sub = (itm) => {
		let tl = gsap.timeline()

		itm.removeClass('active');

		to(itm, {
			opacity: 0,
			duration: .4,
			ease: 'linear'
		}, tl)


		if ($('.main-menu-content-itm.active').length === 0) {
			to($('.main-menu-content-fade'), {
				opacity: 0,
				duration: 1.2
			}, tl)
		}
	}
	const open_main_menu_sub = (itm) => {
		let tl = gsap.timeline()

		itm.addClass('active');

		to($('.main-menu-content-fade'), {
			opacity: 1,
			duration: .4,
			overwrite: true,
		}, tl)

		fromTo(itm, {
				opacity: 0
			},
			{
				opacity: 1,
				duration: .4,
				ease: 'linear',
				overwrite: true,
			}, tl, '<')

		fromTo(itm.find('li a,li>span'), {
				y: "-100%",
			},
			{
				stagger: .1,
				duration: 1,
				y: 0,
				overwrite: true,
			}, tl)

		fromTo(itm.find('.main-menu-catalog-itm'), {
				opacity: 0,
			},
			{
				stagger: .2,
				opacity: 1,
				duration: .8,
				ease: 'linear',
				overwrite: true,
			}, tl, '<')
	}

	const open_header_line_sub = (list) => {
		const tl = gsap.timeline();

		fromTo(list, {opacity: 0}, {opacity: 1, overwrite: true, duration: .2}, tl);
		fromTo(list, {height: '0'}, {height: "auto"}, tl, '<');
		fromTo(list.find("a"), {y: '-100%'}, {y: 0, stagger: .075, duration: .2, overwrite: true}, tl, '<');
	}
	const close_header_line_sub = (list) => {
		const tl = gsap.timeline();

		to(list, {height: '0'}, tl);
		to(list, {opacity: 0, duration: .15}, tl, '<+=.15');
	}
})

const scrollBlock = () => {
	$('html').addClass("scroll-block");
	if(!is_tablet && isMac()) {
		ScrollTrigger.normalizeScroll(false);
	}
}
const scrollUnBlock = () => {
	$('html').removeClass("scroll-block");
	if(!is_tablet && isMac()) {
		ScrollTrigger.normalizeScroll(true);
	}
}