function createCustomSelect() {
	$('.js-select:not(.init)').each(function (i, select) {
		$(this).wrapAll('<div class="js-select-wrap"></div>');

		if ($(this).next().hasClass('custom-select')) {
			return true;
		}

		$(this).after('<div class="custom-select"><div class="custom-select__single"><span class="custom-select__single-label"></span></div><div class="custom-select__dropdown"><ul class="custom-select__dropdown-list"></ul></div><span class="custom-select-result"></span></div>');

		let dropdown = $(this).next();
		let wrap = $(this).parent();
		let options = $(select).find('option');
		let selected = $(this).find('option:selected');
		let list = dropdown.find('.custom-select__dropdown-list');
		let val = selected.val();

		if (!val || val == '' || val == undefined) {
			dropdown.find('.custom-select__single-label').addClass('placeholder');
			wrap.removeClass('select');
		} else {
			dropdown.find('.custom-select__single-label').removeClass('placeholder');
			wrap.addClass('select');
		}

		dropdown.find('.custom-select__single-label').html(selected.text());

		options.each(function (j, o) {
			let display = $(o).data('note') || '';
			list.append('<li class="custom-select__dropdown-item ' + ($(o).is(':selected') ? 'is-active' : '') + '"' + (display ? 'data-note="' + display + '"' : '') + ' data-value="' + $(o).val() + '"><span>' + $(o).text() + '</span></li>')
		});

		$(this).addClass('init');

	});
}

$(function () {
	// Event listeners
	$(document).click(function (e) {
		if (
			$(e.target).closest(".js-select-wrap").length
			|| $(e.target).closest(".fake-select").length
			|| $(e.target).closest(".catalog-filter-itm").length
		) {
			// клик внутри элемента
			return;
		}
		// клик снаружи элемента
		$(".custom-select, .fake-select").removeClass('is-open');
		let dropdown = $('.custom-select__dropdown'),
			tl = gsap.timeline();

		to(dropdown, {height: 0, opacity: 0, duration: .4, overwrite: 1}, tl);
		setCookie("filter_open_checkbox_name", "", 1, "");

	});

// Open/close
	$(document).on('click', '.custom-select__single', function () {
		let self = $(this).closest('.custom-select');

		$('.custom-select').not(self).removeClass('is-open');
		let dropdown = $('.custom-select').not(self).find('.custom-select__dropdown'),
			tl = gsap.timeline();

		to(dropdown, {height: 0, opacity: 0, duration: .4}, tl);

		self.toggleClass('is-open')
		if (self.hasClass('is-open')) {
			self.find('.custom-select__dropdown-item').attr('tabindex', 0);
			self.find('.is-active').focus()
		} else {
			self.find('.custom-select__dropdown-item').removeAttr('tabindex');
			self.focus()
		}

		if ($(this).closest('.js-fake-select').length > 0) {
			let tl = gsap.timeline(),
				dropdown = self.find('.custom-select__dropdown');


			if (self.hasClass('is-open')) {
				to(dropdown, {height: 'auto', opacity: 1}, tl);
			} else {
				to(dropdown, {height: 0, opacity: 0}, tl);
			}

		}

		let form = $(this).closest('.catalog-detail');

		if (form.length > 0) {
			let name = self.find("input").first().attr('name');

			if (!self.hasClass('is-open')) {
				name = '';
			}

			setCookie("filter_open_checkbox_name", name, 1, "");

		}
	});

// Option click
	$(document).on('click', '.custom-select__dropdown-item', function () {
		let dropdown = $(this).closest('.custom-select__dropdown');

		dropdown.find('.is-active').removeClass('is-active');
		$(this).addClass('is-active');


		let text = $(this).text(),
			parent = $(this).closest('.js-select-wrap');

		if ($(this).closest('.js-fake-select').length > 0) {
			let count = dropdown.find('input:checked').length;
			text = "Выбрано " + count;
			if (count > 0) {
				$(this).closest(".custom-select").addClass('has-val');
			} else {
				text = '';
				$(this).closest(".custom-select").removeClass('has-val');
			}
			if(count === 1){
				text = dropdown.find('input:checked').parent().text();
			}
			$(this).closest('.custom-select').find('.custom-select__val').text(text);

		} else {
			$(this).closest('.custom-select').find('.custom-select__single-label').text(text);
		}

		$(this).closest('.custom-select').prev('select').val($(this).data('value')).trigger('change');


		let val = $(this).data('value');

		if (!val || val == '') {
			$(this).closest('.custom-select').find('.custom-select__single-label').addClass('placeholder');
			parent.removeClass('select');
		} else {
			$(this).closest('.custom-select').find('.custom-select__single-label').removeClass('placeholder');
			parent.addClass('select');
		}

		let form = $(this).closest('.catalog-detail');

		if (form.length > 0) {
			let name = $(this).find(".js-checkbox").first().attr('name');

			if (!$(this).closest('.custom-select').hasClass('is-open')) {
				name = '';
			}

			setCookie("filter_open_checkbox_name", name, 1, "");

		}

	});

// Keyboard events
	$(document).on('keydown', '.custom-select', function (event) {
		let focused_option = $($(this).find('.custom-select__dropdown-item:focus')[0] || $(this).find('.custom-select__dropdown-item.is-active')[0]);
		// Space or Enter
		if (event.keyCode == 32 || event.keyCode == 13) {
			if ($(this).hasClass('is-open')) {
				focused_option.trigger('click')
			} else {
				$(this).trigger('click')
			}
			return false
			// Down
		} else if (event.keyCode == 40) {
			if (!$(this).hasClass('is-open')) {
				$(this).trigger('click')
			} else {
				focused_option.next().focus()
			}
			return false
			// Up
		} else if (event.keyCode == 38) {
			if (!$(this).hasClass('is-open')) {
				$(this).trigger('click')
			} else {
				let focused_option = $($(this).find('.custom-select__dropdown-item:focus')[0] || $(this).find('.custom-select__dropdown-item.is-active')[0]);
				focused_option.prev().focus()
			}
			return false
			// Esc
		} else if (event.keyCode == 27) {
			if ($(this).hasClass('is-open')) {
				$(this).trigger('click');
			}
			return false
		}
	});

	$(document).ready(function () {
		createCustomSelect()

	});

	check_filter_select();

})

const check_filter_select = () => {
	let name = getCookie('filter_open_checkbox_name')

	check_filter_more();

	if (!name || name === '') return false;

	let input = $('[name="' + name + '"]');
	let self = input.closest('.custom-select');

	self.addClass('is-open')
	self.find('.custom-select__dropdown-item').attr('tabindex', 0);
	self.find('.is-active').focus()

	if (self.length > 0) {
		let tl = gsap.timeline(),
			dropdown = self.find('.custom-select__dropdown');

		to(dropdown, {height: 'auto', duration: 0, opacity: 1}, tl);
	}

}


const check_filter_more = () => {
	let more = getCookie('filter_open_more'),
		btn = $(".catalog-filter-more"),
		content = btn.closest('form').find('.catalog-filter-form-content')

	if (!more || more === '') return false;

	btn.addClass('active');
	content.addClass('show');

	check_catalog_height(content, 0);
}
