$(() => {

	$(document).on('click', '.js-tab-itm', (e) => {
		let self = $(e.currentTarget),
			index = self.closest('li').index()

		activate_tab(index)

		if ($('.contact-block').length === 0 && !is_tablet) {
			let scroll_pos = $('.js-tab-parent').offset().top;


			$('html, body').animate({scrollTop: scroll_pos}, 300);
		}
	});


	setTimeout(()=>{
		activate_tab(get_active_tab_index(), false);
	},100)

});

const activate_tab = (index, changeUrl = true) => {
	if ($('.js-tab-itm').length === 0) return;

	const progress = $('.podsystem-tabs-nav-progress'),
		tl = gsap.timeline(),
		nav_active = $('.js-tab-itm').eq(index),
		href = nav_active.attr('data-href'),
		content = $('.js-tab-content')

	to(progress, {y: nav_active.position().top, height: nav_active.outerHeight()})

	if (content.eq(index).hasClass('active')) return;

	$('.js-tab-itm').removeClass("active");
	nav_active.addClass('active');

	content.removeClass('active');
	content.eq(index).addClass('active');


	to(content, {opacity: 0, duration: .2}, tl);
	to(content, {height: 0, duration: .3}, tl, ">-.1");


	to(content.eq(index), {height: 'auto', duration: .3}, tl, '<');
	to(content.eq(index), {height: 'auto', duration: .3, opacity: 1}, tl);


	if (changeUrl) {
		if (window.location.search) {
			const urlParams = new URLSearchParams(window.location.search);
			urlParams.set('tabs', href);

			history.replaceState({}, '', '?' + urlParams.toString());
		} else {
			history.replaceState({}, '', '?tabs=' + href);
		}

	}
}


const get_active_tab_index = () => {
	let active_itm = $('.podsystem-tabs-nav-itm.active'),
		active_tab = active_itm.length > 0 ? active_itm.index() : 0

	let url_active_tab = getUrlParam("tabs"),
		tab_btn = $('[data-href="' + url_active_tab + '"]')

	if (tab_btn.length === 0) return active_tab;

	return tab_btn.parent().index();
}

const getUrlParam = (key) => {
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(key);
}