var timeoutCatalogInit;

const page_catalog_init = (timeout = 500) => {
	clearTimeout(timeoutCatalogInit);
	setTimeout(() => page_catalog_init_exect(), timeout)
}

const page_catalog_init_exect = () => {

	if ($('.front-page').length > 0) return;

	createCustomSelect();
	check_filter_select();

	activate_tab(get_active_tab_index(), false);

	$("[data-fancybox]:not(.cloned)").fancybox({
		loop: true,
		animationEffect: "fade",
	});

	$(document).on('click', '[data-fancybox].cloned', function (e) {
		var $slides = $(this)
			.parent()
			.siblings('[data-fancybox]:not(.cloned)');

		$slides
			.eq(($(this).attr("data-index") || 0) % $slides.length)
			.find('a')
			.trigger("click.fb-start", {$trigger: $(this)});

		return false;
	});


	$('.owl-item.cloned,.owl-nav,.owl-dots').remove();
	$('.owl-item').css('width', '');
	if ($('.catalog-detail-slider').length > 0) {
		let loop = $('.catalog-detail-slider-itm').length > 1;

		$('.catalog-detail-slider').owlCarousel({
			items: 1,
			nav: 1,
			dots: 0,
			loop: loop,
			responsive: {
				0: {
					items: 1,
					mouseDrag: true,
				},
				850: {
					items: 1,
					mouseDrag: false,
				}
			},
			onInitialized: (e) => {
				const slider = $(e.currentTarget),
					items = e.relatedTarget._items;
				slider.trigger('stop.owl.autoplay');

				if (items.length <= 1) {
					slider.addClass("single-item-slider");
				}

				slider.find('.owl-nav button').each((key, i) => {
					$(i).html("<svg><use xlink:href='" + window.template_path + "/img/sprite.svg#arrow-right'></use></svg>")
				});
			}, onChanged: (e) => {
			}
		});
	}

	if ($('.object-podsystem-slider').length > 0) {
		console.log($('.object-podsystem-slider-itm').length);
		let length = is_tablet ? 3 : 2,
			loop = $('.object-podsystem-slider-itm').length > length;

		$('.object-podsystem-slider').owlCarousel({
			items: 3.5,
			nav: 1,
			dots: 0,
			loop: loop,
			responsive: {
				0: {
					items: 2
				},
				599: {
					items: 3
				},
				850: {
					items: 2
				}
			},
			onInitialized: (e) => {
				const slider = $(e.currentTarget)

				slider.find('.owl-nav button').each((key, i) => {
					$(i).html("<svg><use xlink:href='" + window.template_path + "/img/sprite.svg#arrow-right'></use></svg>")
				});
			}
		});
	}

	if ($('.podsystem-slider').length > 0) {
		$('.podsystem-slider').each((k, i) => {
			let loop = $(i).find('.cat-list-itm').length > 3,
				desktop_items = $(i).closest('.search-tab-contents__itm').length > 0 ? 3 : 3.5

			$(i).owlCarousel({
				items: desktop_items,
				nav: 1,
				dots: 0,
				loop: loop,
				responsive: {
					0: {
						items: 2
					},
					599: {
						items: 3
					},
					850: {
						items: desktop_items
					}
				},
				onInitialized: (e) => {
					const slider = $(e.currentTarget),
						size = e.page.size,
						count = e.item.count

					if (count > size || e.relatedTarget._clones.length > size) {
						slider.find('.owl-nav').removeClass("disabled");
					}

					slider.find('.owl-nav button').each((key, i) => {
						$(i).html("<svg><use xlink:href='" + window.template_path + "/img/sprite.svg#arrow-right'></use></svg>")
					});
				}, onChanged: (e) => {
					const slider = $(e.currentTarget),
						size = e.page.size,
						count = e.item.count

					if (count > size || e.relatedTarget._clones.length > size) {
						slider.find('.owl-nav').removeClass("disabled");
					}
				}
			});
		})

	}
	$('.owl-loaded').trigger('refresh.owl.carousel');


	$('.catalog-filter-form-content').each((k, i) => {
		check_catalog_height($(i), 0)
	});


	if ($('.range-select').length > 0) {
		$('.range-select').each((k, i) => {
			let min = parseFloat($(i).attr('data-min')),
				max = parseFloat($(i).attr('data-max')),
				step = parseFloat($(i).attr('data-step'))

			$(i).rangeSelect({
				min_val: min,
				max_val: max,
				step: step
			});
		});
	}
}

const check_catalog_height = (content, speed = .6) => {
	let tl = gsap.timeline(),
		height = content.find('.catalog-filter-itm').first().outerHeight();

	if (content.hasClass('show')) {
		to(content, {height: 'auto', duration: speed}, tl);
	} else {
		to(content, {height: height, duration: speed}, tl);
	}

	content.find('.catalog-filter-itm').each((k, i) => {
		if ($(i).position().top > height) {
			$(i).addClass("filter-hide-itm");
		}
	})

}


const filter_mobile_open = (speed = .6) => {
	scrollBlock();
	$('.catalog-filter').addClass('active');
	setCookie('filter_open_mobile', "true", 1, "");

	to($('.catalog-filter'), {
		x: '0',
		duration: speed,
		onComplete: () => {
			$('.catalog-filter').removeClass("mobile-filter-closed");
		}
	}, gsap.timeline());
}
const filter_mobile_close = () => {
	scrollUnBlock();
	$('.catalog-filter').removeClass('active');
	setCookie('filter_open_mobile', "", 1, "");

	to($('.catalog-filter'), {
		x: '100%',
		onComplete: () => {
			$('.catalog-filter').addClass("mobile-filter-closed");
		}
	}, gsap.timeline());
}

$(() => {
	page_catalog_init(0);

	$(document).on('click', '.js-open-sort-modal', (e) => {
		$('.sort-modal').addClass("active");
		scrollBlock();
	});

	$(document).on('click', '.js-close-sort', (e) => {
		$('.sort-modal').removeClass("active");
		scrollUnBlock();
	});

	$(document).on('click', '.js-apply-sort', (e) => {
		let self = $(e.currentTarget)

		if (self.hasClass('active')) {
			self.toggleClass('sort-asc');
			$('.sort-modal').removeClass("active");
			scrollUnBlock();
			return;
		}

		$('.sort-modal__itm').removeClass("active");
		self.addClass("active");
		$('.sort-modal').removeClass("active");
		scrollUnBlock();

	});

	$(document).on('click', '.catalog-filter-more', (e) => {
		let self = $(e.currentTarget),
			content = self.closest('form').find('.catalog-filter-form-content')

		self.toggleClass('active');
		content.toggleClass('show');

		if (self.hasClass('active')) {
			setCookie('filter_open_more', "true", 1, "");
		} else {
			setCookie('filter_open_more', "", 1, "");
		}

		check_catalog_height(content)
	});

	$(document).on('click', '.js-open-mobile-filter', (e) => {
		filter_mobile_open();
	});

	$(document).on('click', '.js-close-filter', (e) => {
		filter_mobile_close();
		return false;
	});

	$(document).on('click', '.js-close-filter,.catalog-filter-submit', (e) => {
		let self = $(e.currentTarget)

		$('.catalog-filter').removeClass('active');
		scrollUnBlock();

		return false;
	});

	$(document).on('click', '.js-catalog-filter-reset', (e) => {
		let self = $(e.currentTarget),
			form = self.closest('.catalog-filter')

		form.find('input').val('').prop('checked', false)
		form.find('.js-fake-select-val').text('')
		form.find('.custom-select').removeClass('has-val')

		return false
	});


	// Если в блоке есть внутренний скролл, то надо отключать нормализацию, а то он работать не будет
	$('.custom-select__dropdown-item, .custom-select__dropdown').on('mouseenter',(e)=>{
		ScrollTrigger.normalizeScroll(false);
	})
	$('.custom-select__dropdown-item, .custom-select__dropdown').on('mouseleave',(e)=>{
		ScrollTrigger.normalizeScroll(true);
	})
})