$(() => {

	lazy_load();

	$(window).on('scroll', () => {
		lazy_load();
	});

	$(window).on('resize', () => {
		lazy_load();
	});

});

const lazy_load = () => {
	var load = $('.js-lazy-load:not(.init)'),
		height = $(window).height(),
		offset = 200,
		scroll = $(window).scrollTop();


	load.each(function (key) {
			if (scroll + height + offset > $(this).offset().top && ($(this).css('display') !== 'none')) {
				var bg = '';

				if (is_mobile) {
					bg = $(this).attr('data-image-mobile');

					if (!bg) {
						bg = $(this).attr('data-image');
					}
				} else {
					bg = $(this).attr('data-image');
				}

				$(this).addClass('init');
				if (bg) {
					$(this).css('background-image', 'url("' + bg + '")')
				} else {
					if (is_mobile) {
						bg = $(this).attr('data-image-src-mobile');
					}
					if (!bg) {
						bg = $(this).attr('data-image-src');
					}
					$(this).attr('src', bg);
				}

				$(this).addClass('load-image');
			}
		}
	);


}