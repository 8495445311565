jQuery(function ($) {
	if ($('#contact-map').length > 0 && !is_tablet) {
		init_ymap_script();
	}
});


function init_ymap_script() {
	//todo сделать API ключ
	let elem = document.createElement('script');
	elem.type = 'text/javascript';
	elem.className = 'js-ymap-script';
	elem.async = 'true';
	elem.src = 'https://api-maps.yandex.ru/2.1/?lang=ru-RU&onload=getYaMap';
	document.getElementsByTagName('body')[0].appendChild(elem);
}

function getYaMap() {
	let myMap, objectManager;
	let myGeoObjects = [];

	if ($('.js-ymap-script').length == 0) {
		init_ymap_script();
	} else {
		ymaps.ready(function () {
			ymap_init('contact-map')
		});
	}


	function ymap_init(id) {
		let map_block = $('#' + id);

		let map_item = getUrlParam('map_item');
		if (map_item !== null && map_item !== "") {
			let active_itm = $('.js-map-itm[data-id="' + map_item + '"]');
			active_itm.addClass("active");
		}

		if ($('.js-map-itm.active').length === 0) {
			$('.js-map-itm').first().addClass("active");
		}

		map_block.addClass('init');

		if (map_block.length <= 0) {
			return false;
		}

		myMap = new ymaps.Map(id, {
			center: [0, 0],
			zoom: 11,
			controls: []
		}, {
			searchControlProvider: 'yandex#search',
			maxZoom: 16,
		});
		objectManager = new ymaps.ObjectManager({
			// Чтобы метки начали кластеризоваться, выставляем опцию.
			clusterize: false,
			clusterHasBalloon: false,
			geoObjectOpenBalloonOnClick: true,
			clusterOpenBalloonOnClick: false,
			gridSize: 256
		});


		myMap.behaviors.disable(['scrollZoom']);

		myMap.geoObjects.add(objectManager);

		const mapAreas = [
			{
				top: 0,
				left: 0,
				width: '40%',
				height: '100%' // Проценты рассчитываются относительно размеров контейнера с картой.
			},
			{
				top: 0,
				left: 0,
				width: '100%',
				height: '30%' // Проценты рассчитываются относительно размеров контейнера с картой.
			},
			{
				bottom: 0,
				left: 0,
				width: '100%',
				height: '20%' // Проценты рассчитываются относительно размеров контейнера с картой.
			},
		];
		// Добавляем каждый блок в менеджер отступов.
		mapAreas.forEach(function (area) {
			// Метод `addArea` менеджера отступов возвращает объект (аксессор), который предоставляет доступ к прямоугольной области в менеджере отступов.
			var accessor = myMap.margin.addArea(area);
			// Если у аксессора вызвать метод `remove`, то область будет удалена из менеджера отступов.
			// Пример: accessor.remove()

			visualizeArea(accessor);
		});

		mapItemRefresh(map_block.attr('data-template-path'));

		$(document).on('click', '.js-map-itm', function () {
			clickItm($(this));
		});

		ZoomLayout(myMap);

		$('.contact-page .js-tab-itm').on('click', (e) => {
			let self = $(e.currentTarget)

			setTimeout((e) => {
				mapItemRefresh(map_block.attr('data-template-path'));
			}, 100)

			$('.available-map-block').animate({scrollTop: 0}, 500);

		});

		clickItm($('.js-tab-content.active .js-map-itm.active'));
	}

	const ZoomLayout = (myMap) => {
		let ZoomLayout = ymaps.templateLayoutFactory.createClass("<div class='ymap-zoom-block'>" +
			"<div id='zoom-in' class='ymap-zoom-block__itm'></div>" +
			"<div id='zoom-out' class='ymap-zoom-block__itm'></div>" +
			"</div>", {

			// Переопределяем методы макета, чтобы выполнять дополнительные действия
			// при построении и очистке макета.
			build: function () {
				// Вызываем родительский метод build.
				ZoomLayout.superclass.build.call(this);

				// Привязываем функции-обработчики к контексту и сохраняем ссылки
				// на них, чтобы потом отписаться от событий.
				this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
				this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);

				// Начинаем слушать клики на кнопках макета.
				$('#zoom-in').bind('click', this.zoomInCallback);
				$('#zoom-out').bind('click', this.zoomOutCallback);
			},

			clear: function () {
				// Снимаем обработчики кликов.
				$('#zoom-in').unbind('click', this.zoomInCallback);
				$('#zoom-out').unbind('click', this.zoomOutCallback);

				// Вызываем родительский метод clear.
				ZoomLayout.superclass.clear.call(this);
			},

			zoomIn: function () {
				var map = this.getData().control.getMap();
				map.setZoom(map.getZoom() + 1, {checkZoomRange: true});
			},

			zoomOut: function () {
				var map = this.getData().control.getMap();
				map.setZoom(map.getZoom() - 1, {checkZoomRange: true});
			}
		})

		let zoomControl = new ymaps.control.ZoomControl({options: {layout: ZoomLayout}});
		myMap.controls.add(zoomControl);
	}

	const mapItemRefresh = (template_path) => {
		let items = $(".js-tab-content.active .js-map-itm"),
			item_active = $(".js-tab-content.active .js-map-itm.active")


		if (item_active.length === 0) {
			items.first().addClass("active");
			item_active = $(".js-tab-content.active .js-map-itm.active")
		}

		myMap.container.fitToViewport();

		myGeoObjects = [];
		myMap.geoObjects.removeAll();

		if (items.length === 0) {
			$('#contact-map').addClass("hide-map");
			return;
		}
		$('#contact-map').removeClass("hide-map");


		items.each(function (index) {
			let coord = $(this).attr('data-coord').split(",")

			let placemark = new ymaps.Placemark([coord[0], coord[1]], {
				//balloonContent: $(this).html(),
				iconContent: index + 1,
			}, {
				iconLayout: 'default#image',
				balloonPanelMaxMapArea: 0,
				iconImageHref: template_path + "/img/svg/placeholder.svg",
				iconImageSize: [28, 38],
				iconImageOffset: [-14, -38]
			});
			myGeoObjects.push(placemark)

		});

		myGeoObjects.forEach(item => {
			myMap.geoObjects.add(item);
		})

		if (item_active.length > 0) {
			let coord = item_active.attr('data-coord').split(",");
			myMap.setCenter(coord);
		}

		//ymaps.geoQuery(myGeoObjects).applyBoundsToMap(myMap);  //делаем масштаб
	}

	const clickItm = (itm) => {
		let coord = itm.attr('data-coord').split(",");


		myMap.setCenter(coord);
		myMap.setZoom(14);

		$('.js-map-itm').removeClass("active");
		itm.addClass("active");

		const urlParams = new URLSearchParams(window.location.search);
		urlParams.set('map_item', itm.attr('data-id'));

		history.replaceState({}, '', '?' + urlParams.toString());

	}

	var container;
	window.visualizeArea = function visualizeArea(accessor) {
		if (!container) {
			container = document.createElement('div');
			container.className = 'area-holder is-hidden';
			document.body.appendChild(container);
		}

		var markElement = document.createElement('div');
		markElement.className = 'rect';

		// Запрашиваем описание прямоугольной области у асессора и на его основе задаем стили DOM-элемента.
		updateElementStyles(markElement, accessor.getArea());

		container.appendChild(markElement);

		var eventsGroup = accessor.events.group();

		eventsGroup.add('change', function () {
			updateElementStyles(markElement, accessor.getArea());
		});

		accessor.events.once('remove', function () {
			eventsGroup.removeAll();
			container.removeChild(markElement);
			markElement = null;
		});
	}

	function updateElementStyles(element, area) {
		element.style.cssText = '';
		for (var key in area) {
			if (area.hasOwnProperty(key)) {
				var value = String(area[key]);
				if (!isNaN(Number(value[value.length - 1]))) {
					value += 'px';
				}
				element.style[key] = value;
			}
		}
	}
}

