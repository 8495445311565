var is_tablet = window.matchMedia('all and (max-width: 850px)').matches;
var is_mobile = window.matchMedia('all and (max-width: 599px)').matches;


$(() => {
	gsap.registerPlugin(ScrollTrigger);

	//ScrollTrigger.config({ignoreMobileResize: true, syncInterval: 99999})
	if (!is_tablet && isMac()) {
		//Нормализация нужна чтобы не лагали параллаксы
		ScrollTrigger.normalizeScroll(true);
	}

	$.fancybox.defaults.hash = false;
	$.fancybox.defaults.animationEffect = "fade";
	$.fancybox.defaults.loop = true;

	let default_theme = $('.header--white').length > 0 ? 'white' : 'black';
	$('.header').attr('data-default-theme', default_theme);


	/*if(!is_tablet){
		ScrollTrigger.config({ignoreMobileResize: true, syncInterval: 99999})
	}else{
		ScrollTrigger.config({ignoreMobileResize: true})
	}*/

	checkFlyChat();

	header_color_check();


	let modal_key = getUrlParam('modal');
	if (modal_key) {
		load_modal(modal_key);
	}

	check_expand_text();

	$(window).on('scroll', (e) => {
		checkFlyChat();

		header_color_check();
	});

	$(document).on('click', '.js-ajax-modal', (elem) => {
		let self = $(elem.currentTarget),
			name = self.attr('data-modal'),
			type = self.attr('data-modal-type'),
			param = {}

		let vacancy_name = self.attr('data-vacancy-name');

		if (vacancy_name) {
			param.vacancy_name = vacancy_name;
		}

		load_modal(name, param, type);

		return false;
	});

	$(document).on('click', '.js-tr-link', (e) => {
		let href = $(e.currentTarget).attr('data-href')

		window.location.href = href;
	});

	$(document).on('click', '.js-close-modal', (e) => {
		let modal = $(e.currentTarget).closest('.modal');

		modal_close(modal);
	});

	$(window).on('resize', () => {
		is_tablet = window.matchMedia('all and (max-width: 850px)').matches;
		is_mobile = window.matchMedia('all and (max-width: 599px)').matches;

		check_expand_text();
	});

	$(document).on('keyup', (e) => {
		const code = e.originalEvent.keyCode;
		// ESC
		if (code === 27) {
			modal_close();
		}
	});

	$(document).on('click', '.text-collapse', (e) => {
		let self = $(e.currentTarget),
			tl = gsap.timeline(),
			height = calc_line_height(self);

		if (!self.hasClass('has-expand')) return;

		self.toggleClass('show');

		if (self.hasClass('show')) {
			to(e.currentTarget, {height: "auto", duration: .8, ease: "easeTextCollapse"}, tl);
		} else {
			to(self, {height: height + 'rem', duration: .8, ease: "easeTextCollapse"}, tl);
		}
	});

	$(window).on('popstate', function (e) {
		//location.reload(true);
	});

	let accordion_tl = gsap.timeline();

	$('.content-grid-accordion__head').on('click', (e) => {
		let self = $(e.currentTarget),
			block = self.closest('.content-grid-accordion'),
			content_active = block.find('.content-grid-accordion__content');

		accordion_tl.pause();
		accordion_tl = gsap.timeline();
		if (content_active.length <= 0) return;

		if (block.hasClass('active')) {
			to(block.find('.content-grid-accordion__content'), {
				height: 0,
				duration: .3,
				overwrite: 1,
				ease: "easeTextCollapse",
				onUpdate: () => {
					//ScrollTrigger.refresh();
				},
				onComplete: () => {
					if ($('.js-parallax').length > 0) {
						ScrollTrigger.refresh();
					}
					block.removeClass('active');
				}
			}, accordion_tl);
			block.removeClass('active');
			return;
		}

		let content = $('.content-grid-accordion.active').not(self).find('.content-grid-accordion__content');
		to(content, {
			height: 0,
			duration: 1,
			overwrite: 1,
			ease: "easeTextCollapse",
			onComplete: () => {
				content.removeClass('active');
				if ($('.js-parallax').length > 0) {
					ScrollTrigger.refresh();
				}
			}
		}, accordion_tl);

		$('.content-grid-accordion').removeClass("active");

		block.addClass('active');

		to(content_active, {
			height: 'auto',
			duration: 1,
			overwrite: 1,
			ease: "easeTextCollapse",
			onUpdate: () => {
				//ScrollTrigger.refresh();
			},
			onComplete: () => {
				block.addClass('active');
				//ScrollTrigger.refresh();
				if ($('.js-parallax').length > 0) {
					ScrollTrigger.refresh();
				}
			}
		}, accordion_tl, '<')

	});

	$('.accordion__head').on('click', (e) => {
		let self = $(e.currentTarget),
			tl = gsap.timeline(),
			parent = self.parent()

		parent.siblings('.accordion').removeClass("active");

		to(parent.siblings('.accordion').find('.accordion__body'), {
			height: 0,
			ease: "easeTextCollapse"
		}, tl);

		parent.toggleClass('active');

		let height = parent.hasClass('active') ? 'auto' : 0;
		to(parent.find('.accordion__body'), {
			height: height,
			ease: "easeTextCollapse"
		}, tl, '<');

	});

	$('.js-scroll-animation-section').each((k, i) => {

		let param = {
			duration: .6,
			stagger: .1,
			ease: 'ease',
			scrollTrigger: {
				start: is_tablet ? "top+=10% bottom" : "top+=400px bottom",
				//end: "+=60%",
				trigger: $(i),
				//scrub: true
			}
		}

		if ($('.company-year-section').length > 0) {
			param["scrollTrigger"]['start'] = is_tablet ? "top+=10% bottom" : "top+=100px bottom"
		}

		fromTo($(i).find('.js-scroll-animation-itm'), {
			opacity: 0,
			y: 80
		}, {
			...param,
			opacity: 1,
			y: 0,
		});


		fromTo($(i).find('.js-scroll-animation-itm-LR'), {
			opacity: 0,
			x: -40
		}, {
			...param,
			opacity: 1,
			x: 0,
		});

	});

	/*$('.js-parallax').each((k, i) => {
		let parent = $(i).closest('.parallax-wrap');
		if (parent.length == 0) {
			parent = $(i).closest('section');
		}

		$(i).css('transform', `translateY(-30%)`);

		fromTo($(i), {
			y: is_mobile ? "-60%" : "-60%",
		}, {
			y: is_mobile ? "50%" : "60%",
			ease: "linear",
			scrollTrigger: {
				start: "top bottom",
				end: "bottom top",
				trigger: parent,
				onUpdate: (self) => {

					let pos = (self.progress.toFixed(2) * 60) - 30;

					console.log(pos);
					console.log(self);
					//$(i).css('transform',`translateY(${pos}%)`);
					to($(i), {y: pos + "%"}, gsap.timeline());

				}
			}
		});

	});*/

	$('.js-parallax').each((k, i) => {
		let parent = $(i).closest('.parallax-wrap');
		if (parent.length == 0) {
			parent = $(i).closest('section');
		}

		fromTo($(i), {
			yPercent: is_mobile ? -60 : -60,
		}, {
			yPercent: is_mobile ? 50 : 60,
			ease: "linear",
			scrollTrigger: {
				start: "top bottom",
				end: "bottom top",
				trigger: parent,
				scrub: is_tablet ? 0.25 : 0,
				//pin: 1,
				refreshPriority: 1,
				immediateRender: true
			}
		});

	});

	$('.js-open-modal-with-content').on('click', (e) => {
		let self = $(e.currentTarget),
			content = self.find('.ajax_modal_content').html(),
			cross = '<div class="modal-close modal-close--absolute js-close-modal"><div class="cross"></div></div>';

		modal_show('center-small');

		$('#modal-container .modal-content').append(cross + content);
	});

})

const check_expand_text = () => {
	$('.text-collapse').each((k, itm) => {
		let height = calc_line_height($(itm));

		$(itm).css('height', height + 'rem');
		const text = $(itm).find('.text-collapse-content');

		if (text.innerHeight() > $(itm).innerHeight()) {
			$(itm).addClass("has-expand");
		} else {
			$(itm).removeClass("has-expand");
			$(itm).css('height', 'auto');
		}
	});
}

const calc_line_height = (elem) => {
	let line = is_mobile ? 10 : 7,
		base_fs = parseFloat(getComputedStyle(document.documentElement).fontSize),
		lh = parseFloat(elem.css('line-height'));

	return (lh * line) / base_fs;
}

CustomEase.create("easeNewton", "0,.25,0,1");
CustomEase.create("easeTextCollapse", "0.5,0,0.5,1");

const modal_close = (modal = $('.modal')) => {
	let tl = gsap.timeline();

	if (modal.length === 0) return;

	modal.removeClass('active');

	$('.wrapper').removeClass("modal-opened");

	if (modal.is('.modal--center, .modal--center-small, .modal--ventfasad')) {
		to(modal.find('.modal-content'), {
			y: '-50%',
			opacity: 0,
			duration: .6
		}, tl, '<+=.2')
	} else {
		if (is_tablet) {
			to(modal.find('.form-footer'), {
				y: '100%',
				opacity: 0,
				duration: .3
			}, tl, '<+=.2')
			to(modal.find('.modal-content'), {
				left: '100%',
				duration: .6
			}, tl, '<+=.2')
			setTimeout(function () {
				to(modal.find('.form-footer, .form-footer .arrow-link'), {
					left: "100%",
					duration: .6
				}, tl, '<')
			}, 0)

		} else {
			to(modal.find('.modal-content'), {
				x: '100%',
				duration: .3
			}, tl, '<+=.2')
		}
	}


	to(modal.find('.modal-before-send, .modal-after-send'), {
		opacity: 0,
		duration: .3
	}, tl, '<')

	to(modal.find('.modal-fade'), {
		opacity: 0,
		duration: .4,
		onComplete: () => {
			modal.find('.modal-content').html("");
			scrollUnBlock();
		}
	}, tl, '<')

	// history.go(-1);
	history.replaceState('modal close', '', window.location.pathname);
}
const load_modal = (name, param = {}, type = false) => {
	if (!name) return false;


	$.ajax({
		url: "/ajax/" + name,
		method: 'get',
		dataType: 'html',
		cache: false,
		success: function (data) {
			let url = '?modal=' + name,
				modal = $('#modal-container .modal'),
				modal_type = type ? type : getUrlParam('modal-type')

			modal.removeClass('active');
			$('.wrapper').removeClass("modal-opened");
			$('#modal-container .modal-content').html('');

			if (modal_type) {
				url += '&modal-type=' + modal_type;
			}

			modal_show(modal_type);


			$('#modal-container .modal-content').append(data);

			let vacancy = param.vacancy_name ? param.vacancy_name : getUrlParam('vacancy_name');
			if (vacancy) {
				modal.find('.js-vacancy-autofill-target input').val(vacancy);
				url += '&vacancy_name=' + vacancy;
			}

			modal_init();

			history.replaceState({modal: name}, '', url);
		}
	});


}

const modal_show = (modal_type = false) => {
	let modal = $('#modal-container .modal')

	init_form_fields();
	scrollBlock();


	$('.wrapper').addClass("modal-opened");

	let tl = gsap.timeline()

	modal.addClass('active');

	to(modal.find('.modal-fade'), {
		opacity: 1,
		duration: .4
	}, tl)

	switch (modal_type) {
		case "center":
		case "ventfasad":
		case "center-small":
			modal.addClass('modal--' + modal_type);

			fromTo(modal.find('.modal-content'),
				{
					y: '-40%',
					x: '-50%',
					opacity: 0
				},
				{
					x: '-50%',
					y: '-50%',
					opacity: 1,
					duration: .6
				}, tl, '<+=.2')

			break;
		case "detail-cols":
			modal.addClass('modal--center modal--cols');

			fromTo(modal.find('.modal-content'),
				{
					y: '-50%',
					x: '-50%',
					opacity: 0
				},
				{
					x: '-50%',
					y: '-50%',
					opacity: 1,
					duration: .6
				}, tl, '<+=.2')


			break;

		default:
			if (is_tablet) {
				to(modal.find('.modal-content'), {
					left: 0,
					duration: .6
				}, tl, '<+=.2')
				setTimeout(function () {
					fromTo(modal.find('.form-footer, .form-footer .arrow-link'), {
						left: "100%"
					}, {
						left: 0,
						duration: .6
					}, tl, '<')
				}, 0)
			} else {
				to(modal.find('.modal-content'), {
					x: 0
				}, tl, '<+=.2')
			}
	}


	fromTo(modal.find('.modal-title,.modal-desc'),
		{opacity: 0, y: 90},
		{opacity: 1, y: 0, duration: .8}, tl, '<')

	fromTo(modal.find('.input,.form-footer,.modal-quote'),
		{opacity: 0, y: 40},
		{opacity: 1, y: 0, duration: .8, stagger: .1}, tl, '<+=.1')

}

const modal_init = () => {
	if ($('.modal-col-slider-itm').length > 0) {
		$('.modal-col-slider').owlCarousel({
			items: 1,
			nav: 1,
			dots: 0,
			loop: 1,
			onInitialized: (e) => {
				const slider = $(e.currentTarget)

				slider.find('.owl-nav button').each((key, i) => {
					$(i).html("<svg><use xlink:href='" + window.template_path + "/img/sprite.svg#arrow-right'></use></svg>")
				});
			}
		});
	}

	init_form_fields();

	form_validate($('#modal-container form'), false);
}

const to = (itm, option, module = gsap, timing = ">") => {
	if (!itm || itm.length === 0) return

	if (!option['ease']) {
		option['ease'] = "easeNewton";
	}

	module.to(itm, option, timing)
}
const fromTo = (itm, optionFrom, optionTo, module = gsap, timing = ">") => {
	if (!itm || itm.length === 0) return

	if (!optionTo['ease']) {
		optionTo['ease'] = "easeNewton";
	}
	module.fromTo(itm, optionFrom, optionTo, timing);
}


const setCookie = (name, value, days = 30, path = "/") => {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=" + path;
}
const getCookie = (name) => {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

const checkFlyChat = () => {
	const scroll = $(window).scrollTop()

	if (scroll > 0) {
		$('.fly-chat').addClass("fly-chat--small");
	} else {
		$('.fly-chat').removeClass("fly-chat--small");
	}
}

const header_color_check = () => {
	let header_color_block = $('.js-header-color'),
		header = $('.header'),
		header_class = false;
	scroll = $(window).scrollTop() + $('.header').height()

	if ($(window).scrollTop() > 0) {
		header.addClass('has-bg');
	} else {
		header.removeClass('has-bg');
	}

	if ($('.front-page').length > 0) return;

	if (header_color_block.length > 0) {
		header_color_block.each((k, i) => {
			let top = $(i).offset().top,
				bottom = top + $(i).height(),
				color = $(i).attr('data-header-color')

			if (scroll > top && scroll < bottom) {
				switch (color) {
					case 'white':
						header_class = "header--white"
						break
				}

				return true;
			}
		})

		if (header_class !== false) {
			if (header.hasClass(header_class)) return;
			header.addClass(header_class);
			return;
		} else {
			header.removeClass("header--white");
		}
	}

	if ($('.footer').length > 0 && scroll > $('.footer').offset().top) {
		header.addClass("header--white");
	} else if (header.attr('data-default-theme') !== 'white') {
		header.removeClass("header--white");
	}
}

function isMac() {
	return navigator.platform.indexOf('Mac') > -1
}