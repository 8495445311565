$(() => {

	static_page_init();

});

const static_page_init = () => {
	if ($('.content-slider__itm,.company-slider').length > 0) {
		let loop = $('.content-slider__itm,.company-slider__itm').length > 1 ? 1 : 0;

		$('.content-slider,.company-slider').owlCarousel({
			items: 1,
			nav: 1,
			dots: 0,
			loop: loop,
			onInitialized: (e) => {
				const slider = $(e.currentTarget),
					items = e.relatedTarget._items;
				slider.trigger('stop.owl.autoplay');

				if (items.length <= 1) {
					slider.addClass("single-item-slider");
				}

				slider.find('.owl-nav button').each((key, i) => {
					$(i).html("<svg><use xlink:href='" + window.template_path + "/img/sprite.svg#arrow-right'></use></svg>")
				});
			}, onChanged: (e) => {
			}
		});
	}

	$('.buro-example-grid').each((k, i) => {

		if (!is_tablet) {
			let itm = $(i).find('.buro-example-grid-itm:nth-child(1)')

			if (itm.length > 0) {
				gsap.fromTo(itm, {
					y: '20%'
				}, {
					y: '0',
					scrollTrigger: {
						start: "top top+=50%",
						end: "+=100%",
						//pin: true,
						trigger: $(i),
						scrub: 1,
					}
				});
			}
			itm = $(i).find('.buro-example-grid-itm:nth-child(3)')

			if (itm.length > 0) {
				gsap.fromTo(itm, {
					y: is_tablet ? '20%' : '40%'
				}, {
					y: is_tablet ? '80%' : '0',
					scrollTrigger: {
						start: "top top+=50%",
						end: "+=100%",
						//pin: true,
						trigger: $(i),
						scrub: 1,
					}
				});
			}

			gsap.fromTo($(i).find(".buro-example-grid-itm__img"), {
				y: '-40%'
			}, {
				y: '0',
				scrollTrigger: {
					start: "top bottom",
					end: "bottom+=50% top",
					trigger: $(i).closest('.buro-example-section'),
					scrub: 1,
				}
			});
		} else {
			let itm = $(i).find('.buro-example-grid-itm:nth-child(2)')

			if (itm.length > 0) {
				gsap.fromTo(itm, {
					y: '10%'
				}, {
					y: '90%',
					scrollTrigger: {
						start: "top bottom",
						end: "bottom top",
						trigger: $(i),
						scrub: 1,
					}
				});

				gsap.fromTo($(i).find(".buro-example-grid-itm__img"), {
					y: '-40%'
				}, {
					y: '0',
					scrollTrigger: {
						start: "top bottom",
						end: "bottom top",
						ease: "linear",
						//pin: true,
						trigger: $(i),
						scrub: 1,
					}
				});
			}
		}

	});
}